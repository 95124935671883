
import { defineComponent, ref } from 'vue'
import { addReport, ReportInt } from '@/firebase/reports'
import { useRouter } from 'vue-router'
import dayjs from 'dayjs'
import ReportsForm from '@/components/forms/ReportsForm.vue'

export default defineComponent({
  name: 'NewReport',
  components: {
    ReportsForm,
  },
  setup() {
    const router = useRouter()
    const address = ref()

    const addNewReport = async (val: ReportInt) => {
      console.log('report', val)
      await addReport({ ...val, time: dayjs(val.time).format() })
      alert('Report Added')
      router.push('/reports')
    }

    const handleAddressChange = (add: string) => {
      address.value = add
    }

    return {
      addNewReport,
      address,
      handleAddressChange,
    }
  },
})
