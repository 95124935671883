import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyBNWcLFVmVagB1VuM4Npkspph1eIS9LrNQ',
  authDomain: 'bluepools-a49a9.firebaseapp.com',
  projectId: 'bluepools-a49a9',
  databaseURL: 'https://bluepools-a49a9.firebaseio.com',
  storageBucket: 'bluepools-a49a9.appspot.com',
  messagingSenderId: '1257732943',
  appId: '1:1257732943:web:e463fc0b6c0116f0',
}

// Initialize Firebase
const app = initializeApp(config)

//initialize firebase auth
const auth = getAuth()

const db = getFirestore(app)

const storage = getStorage(app)

export { app, auth, db, storage }
