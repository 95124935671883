
import { defineComponent, onBeforeMount, ref } from 'vue'
import { getAllEmployees, EmployeeInt } from '@/firebase/employees'
import { getAllCustomers, CustomerInt } from '@/firebase/customers'
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from '@/components/inputs/SelectInput.vue'
import useFormValidation from '@/modules/useFormValidation'

export default defineComponent({
  name: 'WorkorderForm',
  components: {
    TextInput,
    SelectInput,
  },
  props: {
    workorder: {
      type: Object,
      default: () => ({
        title: '',
        description: '',
        employeeId: '',
        employeeName: '',
        customerId: '',
        customerName: '',
        status: 'Todo',
        tags: '',
      }),
    },
  },
  setup(props, context) {
    const validateForm = ref(false)
    const employees = ref()
    const employeeRows = ref()
    const customers = ref()
    const customerRows = ref()
    const { errors, formHasErrors } = useFormValidation()
    const localEmployee = ref()
    const localCustomer = ref()

    const submitForm = () => {
      validateForm.value = true
      const submitVal = { ...props.workorder, ...localEmployee.value, ...localCustomer.value }
      return formHasErrors.value === false && context.emit('submitWorkorderForm', submitVal)
    }

    onBeforeMount(async () => {
      employees.value = await getAllEmployees()
      customers.value = await getAllCustomers()
      customerRows.value = customers.value.map((single: CustomerInt) => ({
        customerName: single.firstName + ' ' + single.lastName,
        customerId: single.id,
      }))
      employeeRows.value = employees.value.map((single: EmployeeInt) => ({
        employeeName: single.firstName + ' ' + single.lastName,
        employeeId: single.id,
      }))
      localEmployee.value = {
        employeeName: props.workorder.employeeName,
        employeeId: props.workorder.employeeId,
      }
      localCustomer.value = {
        customerName: props.workorder.customerName,
        customerId: props.workorder.customerId,
      }
    })

    return {
      props,
      submitForm,
      employees,
      employeeRows,
      customers,
      customerRows,
      errors,
      formHasErrors,
      validateForm,
      localCustomer,
      localEmployee,
    }
  },
})
