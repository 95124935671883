import { collection, doc, setDoc, getDocs, getDoc, deleteDoc, query, orderBy, where } from 'firebase/firestore'
import { db, storage } from '../firebase/config'
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage'
export interface ReportInt {
  id?: any
  address?: string
  chlAdded?: string
  chlorine?: string
  customerId?: string
  customerName?: string
  employeeId?: string
  employeeName?: string
  ph?: string
  phAdded?: string
  ta?: string
  taAdded?: string
  calcium?: string
  calciumAdded?: string
  cya?: string
  cyaAdded?: string
  miscAdded?: string
  filterCleaned?: boolean
  time?: string
  notes?: string
  image?: File
  imageUrl?: string
}

export const addReport = async ({
  address,
  chlAdded,
  chlorine,
  customerId,
  customerName,
  employeeId,
  employeeName,
  ph,
  phAdded,
  ta,
  taAdded,
  calcium,
  calciumAdded,
  cya,
  cyaAdded,
  miscAdded,
  filterCleaned,
  time,
  notes,
  image,
}: ReportInt) => {
  try {
    const reportRef = collection(db, 'reports')
    let downloadUrl = ''
    if (image) {
      const storageRef = ref(storage, `files/${image.name}`)
      const uploadTask = await uploadBytesResumable(storageRef, image)
      downloadUrl = await getDownloadURL(uploadTask.ref)
    }
    const addDoc = await setDoc(doc(reportRef), {
      address,
      chlorine: chlorine || '',
      chlAdded: chlAdded || '',
      customerId: customerId || '',
      customerName: customerName || '',
      employeeId: employeeId || '',
      employeeName: employeeName || '',
      ph: ph || '',
      phAdded: phAdded || '',
      ta: ta || '',
      taAdded: taAdded || '',
      calcium: calcium || '',
      calciumAdded: calciumAdded || '',
      cya: cya || '',
      cyaAdded: cyaAdded || '',
      miscAdded: miscAdded || '',
      filterCleaned: filterCleaned || false,
      time,
      notes: notes || '',
      imageUrl: downloadUrl,
    })
    return addDoc
  } catch (e) {
    console.error('Error: ', e)
  }
  return {}
}

export const getAllReports = async () => {
  try {
    const reportsRef = collection(db, 'reports')
    const q = query(reportsRef, orderBy('time', 'desc'))
    const reportList = await getDocs(q)
    const results: ReportInt[] = []
    reportList.forEach(doc => {
      const data = doc.data()
      const {
        address,
        chlAdded,
        chlorine,
        customerId,
        customerName,
        employeeId,
        employeeName,
        ph,
        phAdded,
        ta,
        taAdded,
        calcium,
        calciumAdded,
        cya,
        cyaAdded,
        miscAdded,
        filterCleaned,
        time,
        notes,
      } = data
      results.push({
        id: doc.id,
        address,
        chlAdded,
        chlorine,
        customerId,
        customerName,
        employeeId,
        employeeName,
        ph,
        phAdded,
        ta,
        taAdded,
        calcium,
        calciumAdded,
        cya,
        cyaAdded,
        miscAdded,
        filterCleaned,
        time,
        notes,
      })
    })
    return results
  } catch (e) {
    console.error('Error getting reports: ', e)
  }
  return []
}

export const getReportsByEmployee = async (id: string) => {
  try {
    const reportsRef = collection(db, 'reports')
    const q = query(reportsRef, where('employee.id', '==', id), orderBy('time', 'desc'))
    const reportList = await getDocs(q)
    const results: ReportInt[] = []
    reportList.forEach(doc => {
      const data = doc.data()
      const {
        address,
        chlAdded,
        chlorine,
        customerId,
        customerName,
        employeeId,
        employeeName,
        ph,
        phAdded,
        ta,
        taAdded,
        calcium,
        calciumAdded,
        cya,
        cyaAdded,
        miscAdded,
        filterCleaned,
        time,
        notes,
      } = data
      results.push({
        id: doc.id,
        address,
        chlAdded,
        chlorine,
        customerId,
        customerName,
        employeeId,
        employeeName,
        ph,
        phAdded,
        ta,
        taAdded,
        calcium,
        calciumAdded,
        cya,
        cyaAdded,
        miscAdded,
        filterCleaned,
        time,
        notes,
      })
    })
    return results
  } catch (e) {
    console.error('Error getting reports: ', e)
  }
  return []
}

export const getSingleReport = async (id: string) => {
  const docRef = doc(db, 'reports', id)
  try {
    const reportList = await getDoc(docRef)
    return reportList.data()
  } catch (e) {
    console.error('Error getting report: ', e)
  }
  return {}
}

export const deleteReport = async (id: string) => {
  try {
    await deleteDoc(doc(db, 'reports', id))
    return 200
  } catch (e) {
    console.error('Error deleting report: ', e)
  }
  return []
}

export const updateReport = async (
  {
    address,
    chlAdded,
    chlorine,
    customerId,
    customerName,
    employeeId,
    employeeName,
    ph,
    phAdded,
    ta,
    taAdded,
    calcium,
    calciumAdded,
    cya,
    cyaAdded,
    miscAdded,
    filterCleaned,
    time,
    notes,
  }: ReportInt,
  { id }: any,
) => {
  try {
    const updateDoc = await setDoc(doc(db, 'reports', id), {
      address,
      chlAdded,
      chlorine,
      customerId,
      customerName,
      employeeId,
      employeeName,
      ph,
      phAdded,
      ta,
      taAdded,
      calcium,
      calciumAdded,
      cya,
      cyaAdded,
      miscAdded,
      filterCleaned,
      time,
      notes,
    })
    return updateDoc
  } catch (e) {
    console.error('Error: ', e)
  }
  return {}
}

export const reportColumns = [
  {
    label: 'Cust',
    field: 'customerName',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter By Customer',
    },
  },
  {
    label: 'Emp',
    field: 'employeeName',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter By Employee',
    },
  },
  {
    label: 'Address',
    field: 'address',
  },
  {
    label: 'CHL',
    field: 'chlorine',
  },
  {
    label: 'CHL ADD',
    field: 'chlAdded',
  },
  {
    label: 'PH',
    field: 'ph',
  },
  {
    label: 'PH ADD',
    field: 'phAdded',
  },
  {
    label: 'TA',
    field: 'ta',
  },
  {
    label: 'TA ADD',
    field: 'taAdded',
  },
  {
    label: 'Calcium',
    field: 'calcium',
  },
  {
    label: 'Cal ADD',
    field: 'calciumAdded',
  },
  {
    label: 'CYA',
    field: 'cya',
  },
  {
    label: 'CYA ADD',
    field: 'cyaAdded',
  },
  {
    label: 'Misc Additions',
    field: 'miscAdded',
  },
  {
    label: 'Filter Cleaned',
    field: 'filterCleaned',
  },
  {
    label: 'Date',
    field: 'time',
  },
  {
    label: 'Notes',
    field: 'notes',
  },
  {
    label: 'Actions',
    field: 'actions',
  },
]
