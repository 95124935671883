<template>
  <router-view />
</template>

<style lang="scss">
:root {
  --green: #53bf9d;
  --red: #f94c66;
  --primary: #6c00ff;
  --secondary: #3c79f5;
  --blue: #3c79f5;
  --light-blue: #2dcddf;
  --bg: #f2deba;
  --brown: #f2deba;
  --box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
  --border-grey: #ddd;
  --text-black: #303030;
  --text-grey: #808080;
  --edit: #ffe15d;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  width: 100%;
  font-size: 16px;
  color: var(--text-black);
}

*,
html,
body {
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow-y: hidden;
}

.page-title {
  text-align: left;
}

input,
textarea,
select {
  width: 100%;
  display: block;
  font-size: 16px;
}

a {
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
}

button {
  background: none;
  cursor: pointer;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;

  &.submit {
    background: var(--green);
    color: white;
    border: 1px solid var(--green);
    padding: 10px 30px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
  }

  &.delete {
    background: red;
    color: white;
    border: 1px solid red;
    padding: 10px 30px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
  }

  &:hover {
    background: #333;
    border-color: #333;
  }
}

.error-wrapper {
  .error {
    background: var(--red);
    color: white;
    margin-top: 5px;
    display: inline-block;
    padding: 2px 10px;
    font-weight: bold;
    font-size: 13px;
  }
}

.page-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action {
  text-decoration: none;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  .unicon {
    margin-right: 3px;
    display: flex;
  }

  &.new {
    background: var(--green);
    color: white;
  }

  &.edit {
    background: var(--edit);
    color: var(--text-black);
  }

  &.view {
    background: var(--light-blue);
    color: white;

    .unicon {
      fill: white;
    }
  }

  &:hover,
  &:focus {
    color: white;
    background: #333;
  }

  &:hover .unicon,
  &:focus .unicon {
    fill: white;
  }
}

body .bm-burger-button {
  position: relative;
  top: 0;
  left: 0;

  .bm-burger-bars {
    background-color: var(--blue);
  }

  .line-style {
    height: 5px;
  }
}

body .bm-menu {
  background-color: white;

  .bm-item-list {
    margin-left: 0;
  }
}

.unicon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-wrapper {
  a {
    .unicon {
      fill: var(--blue);
    }

    &:hover,
    &:focus,
    &.active {
      background: var(--blue);
      color: white;

      .unicon {
        fill: white;
      }
    }
  }
}

.dot-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}
</style>
