import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d34b947e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 1,
  class: "dot-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_unicon = _resolveComponent("unicon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_vue_good_table = _resolveComponent("vue-good-table")!
  const _component_easy_spinner = _resolveComponent("easy-spinner")!

  return (_ctx.localReportRows)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_vue_good_table, {
          columns: _ctx.reportColumns,
          rows: _ctx.localReportRows,
          "search-options": {
        enabled: true,
      },
          ref: "tableData"
        }, {
          "table-actions": _withCtx(() => [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.downloadTable && _ctx.downloadTable(...args))),
              class: "action new"
            }, [
              _createVNode(_component_unicon, {
                name: "file-download",
                width: "20px",
                height: "20px",
                fill: "white"
              }),
              _createTextVNode(" Download ")
            ])
          ]),
          "table-row": _withCtx((props) => [
            (props.column.field == 'actions')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createVNode(_component_router_link, {
                    to: `/reports/view/${props.row.id}`,
                    className: "action view"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_unicon, {
                        name: "eye",
                        width: "15px"
                      }),
                      _createTextVNode("View")
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(props.formattedRow[props.column.field]), 1))
          ]),
          _: 1
        }, 8, ["columns", "rows"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_easy_spinner, {
          type: "dots",
          size: "60",
          color: "#3c79f5"
        })
      ]))
}