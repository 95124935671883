
import { defineComponent } from 'vue'
import ShellWrapper from '@/components/shell/ShellWrapper.vue'
import CustomerTable from '@/components/CustomerTable.vue'

export default defineComponent({
  name: 'ShellView',
  components: {
    ShellWrapper,
  },
  setup() {
    return {}
  },
})
