
import { defineComponent, ref, computed, onBeforeMount } from 'vue'
import { updateEmployee, getSingleEmployee, deleteEmployee, EmployeeInt } from '@/firebase/employees'
import { useRouter, useRoute } from 'vue-router'
import EmployeeForm from '@/components/forms/EmployeeForm.vue'

export default defineComponent({
  name: 'EditEmployee',
  components: {
    EmployeeForm,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const id = computed(() => route.params.id as string)
    const localEmployee = ref()

    const editEmployee = async (val: EmployeeInt) => {
      await updateEmployee({ ...val, id: id.value })
      alert('Employee Updated')
      router.push(`/employees`)
    }

    const remove = async () => {
      await deleteEmployee(id.value)
      alert('Deleted')
      router.push('/employees')
    }

    onBeforeMount(async () => {
      localEmployee.value = await getSingleEmployee(id.value)
    })

    return {
      editEmployee,
      localEmployee,
      remove,
      id,
    }
  },
})
