export const colors = {
  green: '#53bf9d',
  red: '#f94c66',
  primary: '#6c00ff',
  secondary: '#3c79f5',
  blue: '#3c79f5',
  lightBlue: '#2dcddf',
  bg: '#f2deba',
  brown: '#f2deba',
  boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19)',
  borderGrey: '#ddd',
  textBlack: '#303030',
  textGrey: '#808080',
  edit: '#ffe15d',
}
