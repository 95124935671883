
import { defineComponent, ref, computed } from 'vue'
import UserStore from '@/store/UserStore'
import { colors } from '@/theme'

export default defineComponent({
  name: 'SideBar',
  setup() {
    const sidebarOpen = ref(true)
    const sidebarClasses = computed(() => {
      return sidebarOpen.value ? 'sidebar-wrapper' : 'sidebar-wrapper closed'
    })
    const toggleSidebar = () => {
      console.log('toggle sidebar')
      sidebarOpen.value = !sidebarOpen.value
    }
    return { colors, UserStore, toggleSidebar, sidebarOpen, sidebarClasses }
  },
})
