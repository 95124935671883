
import { defineComponent, ref, onBeforeMount } from 'vue'
import { getAllOpenOrders } from '@/firebase/workorders'

export default defineComponent({
  name: 'RecentWorkorders',
  setup() {
    const localWorkorders = ref()

    onBeforeMount(async () => {
      const orders = await getAllOpenOrders()
      localWorkorders.value = orders && [...orders]
    })

    return { localWorkorders }
  },
})
