
import { computed, defineComponent, onBeforeMount, ref, watch, Ref } from 'vue'
import { addRoute, getAllRoutes, RouteInt, CustInt } from '@/firebase/routes'
import { getAllEmployees, EmployeeInt } from '@/firebase/employees'
import { getAllCustomers, CustomerInt } from '@/firebase/customers'
import SelectInput from '@/components/inputs/SelectInput.vue'
import { useRouter, useRoute } from 'vue-router'
import { Container, Draggable } from 'vue3-smooth-dnd'

export default defineComponent({
  name: 'EditRoute',
  components: { Container, Draggable, SelectInput },
  setup() {
    const localRoute: Ref<RouteInt> = ref({
      id: '',
      day: '',
      employeeId: '',
      employeeName: '',
      route: [],
    })
    const router = useRouter()
    const employees = ref()
    const employeeRows = ref()
    const customers = ref()
    const customerRows = ref()
    const allRoutes = ref()
    const localEmployee = ref()
    const daysOfTheWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    const route = useRoute()
    const existingRouteId = computed(() => route.params.id as string)

    const addNewRoute = async () => {
      const submitVal = { ...localRoute.value, day: localRoute.value.day, ...localEmployee.value }
      await addRoute(submitVal)
      alert('Route Added')
      router.push('/routes')
    }

    const applyDrag = (arr: any, dragResult: any) => {
      console.log('arr', arr)
      console.log('dragresult', dragResult)
      const { removedIndex, addedIndex, payload } = dragResult

      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      return result
    }

    const onDrop = (dropResult: any) => {
      console.log('dropresult', dropResult)
      localRoute.value.route = applyDrag(localRoute.value.route, dropResult)
    }

    const addStop = (vals: CustInt) => {
      localRoute.value.route = localRoute.value.route ? [...localRoute.value.route, vals] : [vals]
    }

    const removeStop = (i: number) => {
      console.log('id', i)
      console.log('id', localRoute.value)
      console.log('array', localRoute.value.route?.splice(i, 1))
      // localRoute.value.route = localRoute.value.route?.splice(i, 1)
    }

    const getRandomInt = (min: number, max: number) => {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    }

    watch(
      () => localRoute.value.day,
      () => {
        if (!localRoute.value.employeeId) {
          return
        }
        localRoute.value =
          allRoutes.value?.find(
            (single: RouteInt) =>
              single.employeeId === localRoute.value.employeeId && single.day === localRoute.value.day,
          ) || localRoute.value
      },
      { immediate: true },
    )

    watch(
      () => localRoute.value.employeeId,
      () => {
        if (!localRoute.value.day) {
          return
        }
        localRoute.value =
          allRoutes.value?.find(
            (single: RouteInt) =>
              single.employeeId === localRoute.value.employeeId && single.day === localRoute.value.day,
          ) || localRoute.value
      },
      { immediate: true },
    )

    onBeforeMount(async () => {
      employees.value = await getAllEmployees()
      employeeRows.value = employees.value.map((single: EmployeeInt) => ({
        employeeName: single.firstName + ' ' + single.lastName,
        employeeId: single.id,
      }))
      customers.value = await getAllCustomers()
      customerRows.value = customers.value.map((single: CustomerInt) => ({
        name: single.firstName + ' ' + single.lastName,
        id: single.id,
        address: single.address,
      }))
      allRoutes.value = await getAllRoutes()
      if (existingRouteId.value) {
        localRoute.value = allRoutes.value?.find((single: RouteInt) => single.id === existingRouteId.value)
      }
      localEmployee.value = {
        employeeName: localRoute.value.employeeName,
        employeeId: localRoute.value.employeeId,
      }
    })

    return {
      localRoute,
      addNewRoute,
      employees,
      employeeRows,
      customers,
      customerRows,
      onDrop,
      addStop,
      removeStop,
      getRandomInt,
      daysOfTheWeek,
      existingRouteId,
      localEmployee,
    }
  },
})
