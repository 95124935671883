
import { defineComponent, ref, computed, onBeforeMount } from 'vue'
import { updateCustomer, getSingleCustomer, deleteCustomer, CustomerInt } from '@/firebase/customers'
import { useRouter, useRoute } from 'vue-router'
import CustomerForm from '@/components/forms/CustomerForm.vue'

export default defineComponent({
  name: 'EditCustomer',
  components: {
    CustomerForm,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const id = computed(() => route.params.id as string)
    const localCustomer = ref()

    const editCustomer = async (val: CustomerInt) => {
      await updateCustomer({ ...val, id: id.value })
      alert('Customer Updated')
      router.push(`/customers`)
    }

    const remove = async () => {
      await deleteCustomer(id.value)
      alert('Deleted')
      router.push('/customers')
    }

    onBeforeMount(async () => {
      localCustomer.value = await getSingleCustomer(id.value)
    })

    return {
      editCustomer,
      id,
      localCustomer,
      remove,
    }
  },
})
