import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c1358ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { className: "input-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  className: "error-wrapper"
}
const _hoisted_4 = { className: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createTextVNode(_toDisplayString(_ctx.props.label), 1),
      (_ctx.props.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("input", {
      type: "file",
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFileUpload())),
      accept: "image/*",
      capture: "",
      ref: "file"
    }, null, 544),
    (_ctx.errors[_ctx.props.label])
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.errors[_ctx.props.label]), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}