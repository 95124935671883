
import { defineComponent, ref, onBeforeMount } from 'vue'
import { getAllCustomers, customerColumns } from '@/firebase/customers'
import exportFromJSON from 'export-from-json'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'CustomerTable',
  setup() {
    const localCustomers = ref()
    const localCustomerRows = ref()
    const tableData = ref()

    const downloadTable = () => {
      const data = tableData.value?.processedRows[0]?.children
      const fileName = 'Customers_' + dayjs().format('M-DD-YY-hh:mm:ss')
      const exportType = exportFromJSON.types.csv
      const columnOrder = customerColumns.map((column: any) => column.field)
      const customSort = (a: any, b: any) => {
        const indexOfA = columnOrder.indexOf(a.fieldName)
        const indexOfB = columnOrder.indexOf(b.fieldName)
        if (indexOfA > -1 && indexOfB > -1) return indexOfA - indexOfB
      }

      const beforeTableEncode = (rows: any) => {
        rows.sort(customSort)
        return rows
      }

      exportFromJSON({ data, fileName, exportType, beforeTableEncode })
    }

    onBeforeMount(async () => {
      const cust = await getAllCustomers()
      localCustomers.value = cust && [...cust]
      localCustomerRows.value = cust.map(
        ({
          firstName,
          lastName,
          address,
          email,
          employeeName,
          phone,
          id,
          filterSizes,
          service,
          neighborhood,
          sideOfHouse,
          gateCodesKeys,
          salt,
        }: any) => ({
          firstName,
          lastName,
          address,
          email,
          employeeName,
          phone,
          filterSizes,
          service,
          neighborhood,
          sideOfHouse,
          gateCodesKeys,
          salt,
          id,
        }),
      )
    })

    return {
      localCustomers,
      customerColumns,
      localCustomerRows,
      downloadTable,
      tableData,
    }
  },
})
