
import { defineComponent, ref, onBeforeMount, computed } from 'vue'
import { getAllRoutes, RouteInt, getAllEmployeeRoutes } from '@/firebase/routes'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'RoutesTable',
  setup() {
    const localRoutes = ref()
    const selectedRoute = ref()
    const week: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    const route = useRoute()
    const id = computed(() => route.params.id as string)

    const routesByPerson = computed(() => {
      let result: any = {}
      localRoutes.value.forEach((route: RouteInt) => {
        if (route.employeeName !== undefined && route.day !== undefined) {
          if (!result[route.employeeName]) {
            result[route.employeeName] = {
              [route.day]: { id: route.id, route: route.route },
            }
          } else {
            result[route.employeeName] = {
              ...result[route.employeeName],
              [route.day]: { id: route.id, route: route.route },
            }
          }
        }
      })
      return result
    })

    const setSelectedRoute = (route: RouteInt, person: string, day: string, id: string) =>
      (selectedRoute.value = { route, person, day, id })

    onBeforeMount(async () => {
      let cust
      if (id.value) {
        cust = await getAllEmployeeRoutes(id.value)
      } else {
        cust = await getAllRoutes()
      }
      localRoutes.value = cust && [...cust]
    })

    return {
      localRoutes,
      week,
      routesByPerson,
      selectedRoute,
      setSelectedRoute,
      id,
    }
  },
})
