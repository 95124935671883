
import { defineComponent } from 'vue'
import { addCustomer, CustomerInt } from '@/firebase/customers'

import { useRouter } from 'vue-router'
import CustomerForm from '@/components/forms/CustomerForm.vue'

export default defineComponent({
  name: 'NewCustomer',
  components: {
    CustomerForm,
  },
  setup() {
    const router = useRouter()

    const addNewCustomer = async (val: CustomerInt) => {
      await addCustomer(val)
      alert('Customer Added')
      router.push('/customers')
    }

    return {
      addNewCustomer,
    }
  },
})
