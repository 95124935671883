
import { defineComponent, ref, onBeforeMount } from 'vue'
import { getAllCustomers, CustomerInt } from '@/firebase/customers'
import TextInput from '@/components/inputs/TextInput.vue'
import SelectInput from '@/components/inputs/SelectInput.vue'
import CheckboxInput from '@/components/inputs/CheckboxInput.vue'
import FileInput from '@/components/inputs/FileInput.vue'
import UserStore from '@/store/UserStore'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue-select/dist/vue-select.css'
import dayjs from 'dayjs'
import useFormValidation from '@/modules/useFormValidation'
import {
  chlOpts,
  chlAddOpts,
  phOpts,
  phAddOpts,
  taOpts,
  taAddOpts,
  calOpts,
  calAddOpts,
  cyaOpts,
  cyaAddOpts,
} from '@/modules/formOptions'

export default defineComponent({
  name: 'ReportsForm',
  components: { Datepicker, SelectInput, TextInput, CheckboxInput, FileInput },
  props: {
    report: {
      type: Object,
      default: () => ({
        customerId: '',
        customerName: '',
        address: '',
        chlorine: '',
        chlAdded: '',
        ph: '',
        phAdded: '',
        ta: '',
        taAdded: '',
        notes: '',
      }),
    },
  },
  setup(props, context) {
    const customers = ref()
    const customerRows = ref()
    const employee = ref()
    const { getters: userGetters } = UserStore
    const time = ref()
    const now = dayjs()
    const validateForm = ref(false)
    const { errors, formHasErrors } = useFormValidation()
    const localCustomer = ref()
    const image = ref()

    const submitReport = () => {
      validateForm.value = true
      const submitVal = {
        ...props.report,
        image: image.value,
        ...employee.value,
        ...localCustomer.value,
        time: time.value,
      }
      return formHasErrors.value !== true && context.emit('submitReportForm', submitVal)
    }

    const updateAddress = (customerId: string) => {
      if (!customerId) {
        return
      }
      const found = customers.value.filter((cust: CustomerInt) => cust?.id === customerId)
      context.emit('foundAddress', found[0].address)
    }

    const updateFile = (file: File) => {
      image.value = file
    }

    onBeforeMount(async () => {
      customers.value = await getAllCustomers()
      customerRows.value = customers.value.map((single: CustomerInt) => ({
        customerName: single.firstName + ' ' + single.lastName,
        customerId: single.id,
      }))
      employee.value = {
        employeeId: userGetters.employee.id,
        employeeName: `${userGetters.employee.firstName} ${userGetters.employee.lastName}`,
      }
      time.value = now
      localCustomer.value = {
        customerName: props.report.customerName,
        customerId: props.report.customerId,
      }
    })

    return {
      submitReport,
      customerRows,
      time,
      props,
      errors,
      employee,
      customers,
      updateAddress,
      localCustomer,
      validateForm,
      chlOpts,
      chlAddOpts,
      phOpts,
      phAddOpts,
      taOpts,
      taAddOpts,
      calOpts,
      calAddOpts,
      cyaOpts,
      cyaAddOpts,
      updateFile,
    }
  },
})
