
import { defineComponent, ref, onBeforeMount, watch } from 'vue'
import { getAllOrders, workorderColumns, markComplete } from '@/firebase/workorders'
import exportFromJSON from 'export-from-json'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'WorkorderTable',
  setup() {
    const localWorkorders = ref()
    const localWorkorderRows = ref()
    const tableData = ref()
    const isLoading = ref()

    const downloadTable = () => {
      const data = tableData.value?.processedRows[0]?.children
      const fileName = 'Workorders_' + dayjs().format('M-DD-YY-hh:mm:ss')
      const exportType = exportFromJSON.types.csv
      const columnOrder = workorderColumns.map((column: any) => column.field)
      const customSort = (a: any, b: any) => {
        const indexOfA = columnOrder.indexOf(a.fieldName)
        const indexOfB = columnOrder.indexOf(b.fieldName)
        if (indexOfA > -1 && indexOfB > -1) return indexOfA - indexOfB
      }

      const beforeTableEncode = (rows: any) => {
        rows.sort(customSort)
        return rows
      }

      exportFromJSON({ data, fileName, exportType, beforeTableEncode })
    }

    const completeWorkorder = async (id: string) => {
      isLoading.value = { ...isLoading.value, [id]: 'loading' }
      await markComplete(id)
      await refreshRows()
      isLoading.value = { ...isLoading.value, [id]: 'hidden' }
    }

    const refreshRows = async () => {
      const cust = await getAllOrders()
      localWorkorders.value = cust && [...cust]
      localWorkorderRows.value = localWorkorders.value.map(
        ({ title, description, employeeId, employeeName, customerId, customerName, status, comments, id }: any) => ({
          title,
          description,
          employeeId,
          employeeName,
          customerId,
          customerName,
          status,
          comments,
          id,
        }),
      )
    }

    onBeforeMount(async () => {
      await refreshRows()
    })

    return {
      localWorkorders,
      workorderColumns,
      localWorkorderRows,
      downloadTable,
      tableData,
      completeWorkorder,
      isLoading,
    }
  },
})
