
import { defineComponent, ref, computed, onBeforeMount } from 'vue'
import { updateWorkorder, getSingleWorkorder, deleteWorkorder, WorkorderInt } from '@/firebase/workorders'
import { useRouter, useRoute } from 'vue-router'
import WorkorderForm from '@/components/forms/WorkorderForm.vue'

export default defineComponent({
  name: 'EditWorkorder',
  components: {
    WorkorderForm,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const id = computed(() => route.params.id as string)
    const localWorkorder = ref()

    const editWorkorder = async (val: WorkorderInt) => {
      await updateWorkorder({ ...val, id: id.value })
      alert('Updated')
      router.push(`/workorders`)
    }

    const remove = async () => {
      await deleteWorkorder(id.value)
      alert('Deleted')
      router.push('/workorders')
    }

    onBeforeMount(async () => {
      localWorkorder.value = await getSingleWorkorder(id.value)
    })

    return {
      editWorkorder,
      id,
      localWorkorder,
      remove,
    }
  },
})
