
import { defineComponent, ref } from 'vue'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { useRouter } from 'vue-router'
import TextInput from '@/components/inputs/TextInput.vue'
import EmailInput from '@/components/inputs/EmailInput.vue'
import useFormValidation from '@/modules/useFormValidation'

export default defineComponent({
  name: 'RegisterView',
  components: {
    TextInput,
    EmailInput,
  },
  setup(props) {
    const email = ref('')
    const password = ref('')
    const errMsg = ref('')
    const loading = ref(false)
    const router = useRouter()
    const { errors, formHasErrors } = useFormValidation()
    const validateForm = ref(false)
    const register = () => {
      validateForm.value = true
      if (formHasErrors.value === false) {
        loading.value = true
        createUserWithEmailAndPassword(getAuth(), email.value, password.value)
          .then(() => {
            router.push('/dashboard')
          })
          .catch(error => {
            console.error(error.code)
            alert(error.message)
            errMsg.value = error.message
          })
          .finally(() => {
            loading.value = false
          })
      }
    }
    return {
      props,
      email,
      password,
      register,
      validateForm,
      errors,
      errMsg,
      loading,
    }
  },
})
