import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import VueGoodTablePlugin from 'vue-good-table-next'
import Unicon from 'vue-unicons'
import easySpinner from 'vue-easy-spinner'
import {
  uniEdit,
  uniPlus,
  uniEye,
  uniDashboard,
  uniSwimmer,
  uniWater,
  uniMap,
  uniConstructor,
  uniFileCheck,
  uniFlask,
  uniSignout,
  uniFileDownload,
  uniCheck,
  uniArrowLeft,
} from 'vue-unicons/dist/icons'

Unicon.add([
  uniEdit,
  uniPlus,
  uniEye,
  uniDashboard,
  uniSwimmer,
  uniWater,
  uniMap,
  uniConstructor,
  uniFileCheck,
  uniFlask,
  uniSignout,
  uniFileDownload,
  uniCheck,
  uniArrowLeft,
])

const app = createApp(App)

app.use(router).use(Unicon).use(VueGoodTablePlugin).use(easySpinner, {
  /*options*/
  prefix: 'easy',
})

app.mount('#app')
