
import { defineComponent } from 'vue'
import { addOrder, WorkorderInt } from '@/firebase/workorders'
import { useRouter } from 'vue-router'
import WorkorderForm from '@/components/forms/WorkorderForm.vue'

export default defineComponent({
  name: 'NewWorkorder',
  components: {
    WorkorderForm,
  },
  setup() {
    const router = useRouter()

    const addNewWorkorder = async (val: WorkorderInt) => {
      await addOrder(val)
      alert('Workorder Added')
      router.push('/workorders')
    }

    return {
      addNewWorkorder,
    }
  },
})
