import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-794d5362"),n=n(),_popScopeId(),n)
const _hoisted_1 = { className: "input-wrapper checkbox" }
const _hoisted_2 = ["checked", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      checked: _ctx.inputValue,
      disabled: _ctx.props.disabled,
      type: "checkbox",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", null, _toDisplayString(_ctx.props.label), 1)
  ]))
}