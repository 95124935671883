
import { defineComponent } from 'vue'
import { addEmployee, EmployeeInt } from '@/firebase/employees'
import { useRouter } from 'vue-router'
import EmployeeForm from '@/components/forms/EmployeeForm.vue'

export default defineComponent({
  name: 'NewEmployee',
  components: {
    EmployeeForm,
  },
  setup() {
    const router = useRouter()

    const addNewEmployee = async (val: EmployeeInt) => {
      await addEmployee(val)
      alert('Employee Added')
      router.push('/employees')
    }
    return {
      addNewEmployee,
    }
  },
})
