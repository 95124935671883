
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getSingleReport } from '@/firebase/reports'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'ReportsView',
  setup() {
    const route = useRoute()
    const id = computed(() => route.params.id as string)
    const localReport = ref()

    onBeforeMount(async () => {
      const cust = await getSingleReport(id.value)
      localReport.value = cust
    })
    return {
      localReport,
      dayjs,
    }
  },
})
