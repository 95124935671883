<template>
  <shell-wrapper>
    <h1 class="page-title">Dashboard</h1>
    <h3>Welcome {{ UserStore.getters?.user?.email }}</h3>
    <recent-workorders />
  </shell-wrapper>
</template>

<script>
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '@/firebase/config'
import { useRouter } from 'vue-router'
import { defineComponent, onBeforeUnmount, onBeforeMount } from 'vue'
import ShellWrapper from '@/components/shell/ShellWrapper'
import UserStore from '@/store/UserStore'
import RecentWorkorders from '@/components/widgets/RecentWorkorders.vue'

export default defineComponent({
  name: 'DashboardView',
  components: {
    ShellWrapper,
    RecentWorkorders,
  },
  setup(props) {
    const router = useRouter()
    const authListener = onAuthStateChanged(auth, function (user) {
      if (!user) {
        router.push('/login')
      }
    })
    const checkForUser = () => {
      if (!UserStore.getters.loggedIn) {
        router.push('/login')
      }
    }

    onBeforeUnmount(() => {
      authListener()
    })
    onBeforeMount(() => {
      checkForUser()
    })
    return {
      props,
      UserStore,
    }
  },
})
</script>
