
import { computed, defineComponent, ref, onBeforeMount } from 'vue'
import { getAllReports, reportColumns, ReportInt, getReportsByEmployee } from '@/firebase/reports'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useRoute } from 'vue-router'
import exportFromJSON from 'export-from-json'

dayjs.extend(localizedFormat)

export default defineComponent({
  name: 'ReportsTable',
  setup() {
    const localReports = ref()
    const localReportRows = ref()
    const route = useRoute()
    const id = computed(() => route.params.id as string)
    const tableData = ref()

    const downloadTable = () => {
      const data = tableData.value?.processedRows[0]?.children
      const fileName = 'Reports_' + dayjs().format('M-DD-YY-hh:mm:ss')
      const exportType = exportFromJSON.types.csv
      const columnOrder = reportColumns.map((column: any) => column.field)
      const customSort = (a: any, b: any) => {
        const indexOfA = columnOrder.indexOf(a.fieldName)
        const indexOfB = columnOrder.indexOf(b.fieldName)
        if (indexOfA > -1 && indexOfB > -1) return indexOfA - indexOfB
      }
      const filteredColumns = reportColumns.reduce(
        (acc: any, column: any) => (column?.field !== 'Actions' ? [...acc, column?.field] : acc),
        [],
      )

      const removeExtraRows = (rows: any) =>
        rows.filter((value: any, index: any) => filteredColumns.indexOf(value.fieldName) >= 0)

      const beforeTableEncode = (rows: any) => {
        rows.sort(customSort)
        return removeExtraRows(rows)
      }

      exportFromJSON({ data, fileName, exportType, beforeTableEncode })
    }

    onBeforeMount(async () => {
      let cust
      if (id.value) {
        cust = await getReportsByEmployee(id.value)
      } else {
        cust = await getAllReports()
      }
      localReports.value = cust && [...cust]
      localReportRows.value = localReports.value.map(
        ({
          id,
          address,
          chlorine,
          chlAdded,
          customerName,
          employeeName,
          ph,
          phAdded,
          ta,
          taAdded,
          calcium,
          calciumAdded,
          cya,
          cyaAdded,
          miscAdded,
          filterCleaned,
          time,
          notes,
        }: ReportInt) => ({
          address,
          chlorine,
          chlAdded,
          customerName,
          employeeName,
          ph,
          phAdded,
          ta,
          taAdded,
          calcium,
          calciumAdded,
          cya,
          cyaAdded,
          miscAdded,
          filterCleaned,
          time: dayjs(time).format('lll'),
          notes,
          id,
        }),
      )
    })

    return {
      downloadTable,
      localReports,
      reportColumns,
      localReportRows,
      tableData,
    }
  },
})
