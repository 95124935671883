import { computed, reactive } from '@vue/reactivity'
import useValidators from '@/modules/validators'

type CustError = {
  [key: string]: string
}

const errors = reactive<CustError>({})

export default function useFormValidation() {
  const { isEmpty, minLength, isEmail, isNum } = useValidators()

  const validateTextField = (fieldName: string, fieldValue: string) => {
    errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : minLength(fieldName, fieldValue, 2)
  }

  const validateEmailField = (fieldName: string, fieldValue: string) => {
    errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isEmail(fieldName, fieldValue)
  }

  const validatePhoneField = (fieldName: string, fieldValue: string) => {
    errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isNum(fieldName, fieldValue)
  }

  const validateSelectField = (fieldName: string, fieldValue: string) => {
    errors[fieldName] = !fieldValue || Object.values(fieldValue)[0] === '' ? 'This field is required' : ''
  }

  const formHasErrors = computed(() => {
    for (const error in errors) {
      if (errors[error]) {
        return true
      }
      return false
    }
  })

  return { errors, formHasErrors, validateTextField, validateEmailField, validatePhoneField, validateSelectField }
}
