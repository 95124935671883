import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_text_input, {
      label: "Title",
      modelValue: _ctx.props.workorder.title,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.workorder.title) = $event)),
      required: true,
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createVNode(_component_text_input, {
      label: "Description",
      modelValue: _ctx.props.workorder.description,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.props.workorder.description) = $event)),
      required: true,
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    (_ctx.employeeRows)
      ? (_openBlock(), _createBlock(_component_select_input, {
          key: 0,
          label: "Employee",
          displayLabel: "employeeName",
          rows: _ctx.employeeRows,
          modelValue: _ctx.localEmployee,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localEmployee) = $event)),
          required: true,
          validate: _ctx.validateForm
        }, null, 8, ["rows", "modelValue", "validate"]))
      : _createCommentVNode("", true),
    (_ctx.customerRows)
      ? (_openBlock(), _createBlock(_component_select_input, {
          key: 1,
          label: "Customer",
          displayLabel: "customerName",
          rows: _ctx.customerRows,
          modelValue: _ctx.localCustomer,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localCustomer) = $event)),
          required: true,
          validate: _ctx.validateForm
        }, null, 8, ["rows", "modelValue", "validate"]))
      : _createCommentVNode("", true),
    _createVNode(_component_select_input, {
      label: "Status",
      modelValue: _ctx.props.workorder.status,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.props.workorder.status) = $event)),
      rows: ['Todo', 'In Progress', 'Complete'],
      required: true,
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createElementVNode("button", {
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
      className: "submit"
    }, "Submit")
  ]))
}