import { collection, doc, setDoc, getDocs, getDoc, deleteDoc, query, where } from 'firebase/firestore'
import { db } from '../firebase/config'

export interface EmployeeInt {
  id?: any
  firstName?: string
  lastName?: string
  address?: string
  email?: string
  phone?: string
}

export const addEmployee = async ({ firstName, lastName, address, email, phone }: EmployeeInt) => {
  const employeeRef = collection(db, 'employees')
  try {
    const addDoc = await setDoc(doc(employeeRef), { firstName, lastName, address, email, phone })
    return addDoc
  } catch (e) {
    console.error('Error: ', e)
  }
  return {}
}

export const getAllEmployees = async () => {
  try {
    const employeeList = await getDocs(collection(db, 'employees'))
    const results: EmployeeInt[] = []
    employeeList.forEach(doc => {
      const data = doc.data()
      results.push({ id: doc.id, ...data })
    })
    return results
  } catch (e) {
    console.error('Error getting employees: ', e)
  }
  return []
}

export const getSingleEmployee = async (id: string) => {
  const docRef = doc(db, 'employees', id)
  try {
    const employeeList = await getDoc(docRef)

    const employeeListData: EmployeeInt | undefined = employeeList.data()
    return employeeListData
  } catch (e) {
    console.error('Error getting employee: ', e)
  }
  return {}
}

export const getEmployeeByEmail = async (email: string) => {
  const employeesRef = collection(db, 'employees')
  const q = query(employeesRef, where('email', '==', email))
  try {
    const employeeList = await getDocs(q)

    const results: EmployeeInt[] = []
    employeeList.forEach(doc => {
      const data = doc.data()
      results.push({ id: doc.id, ...data })
    })
    return results[0]
  } catch (e) {
    console.error('Error getting employee: ', e)
  }
  return {}
}

export const deleteEmployee = async (id: string) => {
  try {
    await deleteDoc(doc(db, 'employees', id))
    return 200
  } catch (e) {
    console.error('Error deleting employee: ', e)
  }
  return []
}

export const updateEmployee = async ({ firstName, lastName, address, email, phone, id }: EmployeeInt) => {
  try {
    const updateDoc = await setDoc(doc(db, 'employees', id), {
      firstName,
      lastName,
      address,
      email,
      phone,
    })
    return updateDoc
  } catch (e) {
    console.error('Error: ', e)
  }
  return {}
}

export const employeeColumns = [
  {
    label: 'First Name',
    field: 'firstName',
  },
  {
    label: 'Last Name',
    field: 'lastName',
  },
  {
    label: 'Address',
    field: 'address',
  },
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'Phone',
    field: 'phone',
  },
  {
    label: 'Actions',
    field: 'Actions',
  },
]
