const chlOpts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
const chlAddOpts = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
const phOpts = [6.8, 7.0, 7.2, 7.4, 7.6, 7.8, 8.0, 8.2, 8.4]
const phAddOpts = [0, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]
const taOpts = [20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120]
const taAddOpts = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
const calOpts = [
  150, 160, 170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350,
]
const calAddOpts = [6, 8, 10, 12, 14, 16, 18, 20]
const cyaOpts = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
const cyaAddOpts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

export { chlOpts, chlAddOpts, phOpts, phAddOpts, taOpts, taAddOpts, calOpts, calAddOpts, cyaOpts, cyaAddOpts }
