import { reactive, readonly, computed, watch } from 'vue'
import { getEmployeeByEmail } from '@/firebase/employees'

const defaultState = {
  loggedIn: false,
  user: null,
  employee: null,
}

const storageKey = 'user-store'

const userStore = sessionStorage.getItem(storageKey)
  ? JSON.parse(sessionStorage.getItem(storageKey) || '')
  : { ...defaultState }

const state = reactive(userStore)

const getters = {
  user: computed(() => state.user),
  employee: computed(() => state.employee),
  loggedIn: computed(() => state.loggedIn),
  role: computed(() => state.employee?.role),
}

const mutations = {
  SET_LOGGED_IN: (value: boolean): void => {
    state.loggedIn = value
  },
  SET_USER: (value: any): void => {
    state.user = value
  },
  SET_EMPLOYEE: (value: any): void => {
    state.employee = value
  },
}

const actions = {
  loginUser: async (data: any): Promise<void> => {
    mutations.SET_LOGGED_IN(true)
    mutations.SET_USER(data)
    const newEmployee = await getEmployeeByEmail(data.email)
    mutations.SET_EMPLOYEE(newEmployee)
  },
}

watch(
  () => state,
  () => sessionStorage.setItem(storageKey, JSON.stringify(state)),
  { immediate: true, deep: true },
)

export default {
  getters: readonly(getters),
  actions: readonly(actions),
  mutations: readonly(mutations),
}
