import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_email_input = _resolveComponent("email-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_phone_input = _resolveComponent("phone-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_text_input, {
      label: "First Name",
      modelValue: _ctx.props.customer.firstName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.props.customer.firstName) = $event)),
      required: true,
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createVNode(_component_text_input, {
      label: "Last Name",
      modelValue: _ctx.props.customer.lastName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.props.customer.lastName) = $event)),
      required: true,
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createVNode(_component_text_input, {
      label: "Address",
      modelValue: _ctx.props.customer.address,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.props.customer.address) = $event)),
      required: true,
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createVNode(_component_email_input, {
      label: "Email",
      modelValue: _ctx.props.customer.email,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.props.customer.email) = $event)),
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    (_ctx.employeeRows)
      ? (_openBlock(), _createBlock(_component_select_input, {
          key: 0,
          label: "Employee",
          displayLabel: "employeeName",
          rows: _ctx.employeeRows,
          modelValue: _ctx.localEmployee,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localEmployee) = $event)),
          required: true,
          validate: _ctx.validateForm
        }, null, 8, ["rows", "modelValue", "validate"]))
      : _createCommentVNode("", true),
    _createVNode(_component_phone_input, {
      label: "Phone",
      modelValue: _ctx.props.customer.phone,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.props.customer.phone) = $event)),
      required: true,
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createVNode(_component_text_input, {
      label: "Filter Size",
      modelValue: _ctx.props.customer.filterSizes,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.props.customer.filterSizes) = $event)),
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createVNode(_component_text_input, {
      label: "Service",
      modelValue: _ctx.props.customer.service,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.props.customer.service) = $event)),
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createVNode(_component_text_input, {
      label: "Neighborhood",
      modelValue: _ctx.props.customer.neighborhood,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.props.customer.neighborhood) = $event)),
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createVNode(_component_text_input, {
      label: "Side of House",
      modelValue: _ctx.props.customer.sideOfHouse,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.props.customer.sideOfHouse) = $event)),
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createVNode(_component_text_input, {
      label: "Gate Codes/Keys",
      modelValue: _ctx.props.customer.gateCodesKeys,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.props.customer.gateCodesKeys) = $event)),
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createVNode(_component_text_input, {
      label: "Salt",
      modelValue: _ctx.props.customer.salt,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.props.customer.salt) = $event)),
      validate: _ctx.validateForm
    }, null, 8, ["modelValue", "validate"]),
    _createElementVNode("button", {
      onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args))),
      disabled: _ctx.formHasErrors,
      className: "submit"
    }, "Submit", 8, _hoisted_1)
  ]))
}