
import { defineComponent, ref, watch } from 'vue'
import useFormValidation from '@/modules/useFormValidation'
import vSelect from 'vue-select'

export default defineComponent({
  name: 'SelectInput',
  components: { vSelect },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      required: true,
    },
    displayLabel: {
      type: String,
      default: 'name',
    },
  },
  setup(props, context) {
    const inputValue = ref()
    const { validateSelectField, errors } = useFormValidation()
    const validateInput = () => {
      props.required && validateSelectField(props.label, inputValue.value)
    }

    const handleInput = () => {
      validateInput()
      context.emit('update:modelValue', inputValue.value)
    }

    watch(
      () => props.modelValue,
      val => {
        inputValue.value = val
      },
      { immediate: true },
    )

    watch(
      () => props.validate,
      val => val && validateInput(),
      { immediate: true },
    )

    watch(
      () => inputValue.value,
      () => {
        handleInput()
      },
      { immediate: true },
    )

    return {
      props,
      validateInput,
      errors,
      inputValue,
      handleInput,
    }
  },
})
