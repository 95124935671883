
import { defineComponent, ref } from 'vue'
import TextInput from '@/components/inputs/TextInput.vue'
import EmailInput from '@/components/inputs/EmailInput.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import useFormValidation from '@/modules/useFormValidation'

export default defineComponent({
  name: 'EmployeeForm',
  components: {
    EmailInput,
    TextInput,
    PhoneInput,
  },
  props: {
    employee: {
      type: Object,
      default: () => ({
        firstName: '',
        lastName: '',
        address: '',
        email: '',
        phone: '',
      }),
    },
  },
  setup(props, context) {
    const validateForm = ref(false)
    const { errors, formHasErrors } = useFormValidation()

    const submitForm = () => {
      validateForm.value = true
      return formHasErrors.value === false && context.emit('submitEmployeeForm', props.employee)
    }

    return {
      props,
      submitForm,
      errors,
      formHasErrors,
      validateForm,
    }
  },
})
