
import { defineComponent, onBeforeMount, ref } from 'vue'
import { getAllEmployees, EmployeeInt } from '@/firebase/employees'
import TextInput from '@/components/inputs/TextInput.vue'
import EmailInput from '@/components/inputs/EmailInput.vue'
import PhoneInput from '@/components/inputs/PhoneInput.vue'
import SelectInput from '@/components/inputs/SelectInput.vue'
import useFormValidation from '@/modules/useFormValidation'

export default defineComponent({
  name: 'CustomerForm',
  components: {
    EmailInput,
    TextInput,
    PhoneInput,
    SelectInput,
  },
  props: {
    customer: {
      type: Object,
      default: () => ({
        firstName: '',
        lastName: '',
        address: '',
        email: '',
        employeeId: '',
        employeeName: '',
        phone: '',
        filterSizes: '',
        service: '',
        neighborhood: '',
        sideOfHouse: '',
        gateCodesKeys: '',
        salt: '',
      }),
    },
  },
  setup(props, context) {
    const validateForm = ref(false)
    const employees = ref()
    const employeeRows = ref()
    const { errors, formHasErrors } = useFormValidation()
    const localEmployee = ref()

    const submitForm = () => {
      validateForm.value = true
      const submitVal = { ...props.customer, ...localEmployee.value }

      return formHasErrors.value === false && context.emit('submitCustomerForm', submitVal)
    }

    onBeforeMount(async () => {
      employees.value = await getAllEmployees()
      employeeRows.value = employees.value.map((single: EmployeeInt) => ({
        employeeName: single.firstName + ' ' + single.lastName,
        employeeId: single.id,
      }))
      localEmployee.value = {
        employeeName: props.customer.employeeName,
        employeeId: props.customer.employeeId,
      }
    })

    return {
      props,
      submitForm,
      employees,
      errors,
      formHasErrors,
      validateForm,
      employeeRows,
      localEmployee,
    }
  },
})
