import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import DashboardView from '../views/DashboardView.vue'
import ShellView from '../views/ShellView.vue'
import NewCustomer from '../views/NewCustomer.vue'
import EditCustomer from '../views/EditCustomer.vue'
import CustomerTable from '@/components/CustomerTable.vue'
import EmployeesView from '../views/EmployeesView.vue'
import NewEmployee from '../views/NewEmployee.vue'
import EditEmployee from '../views/EditEmployee.vue'
import EmployeeTable from '@/components/EmployeeTable.vue'
import RoutesView from '../views/RoutesView.vue'
import RoutesTable from '@/components/RoutesTable.vue'
import EditRoute from '../views/EditRoute.vue'
import ReportsView from '../views/ReportsView.vue'
import ReportsTable from '@/components/ReportsTable.vue'
import NewReport from '../views/NewReport.vue'
import WorkordersView from '../views/WorkordersView.vue'
import NewWorkorder from '../views/NewWorkorder.vue'
import EditWorkorder from '../views/EditWorkorder.vue'
import WorkorderTable from '@/components/WorkorderTable.vue'
import ViewSingleReport from '@/views/ViewSingleReport.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardView,
  },
  {
    path: '/customers',
    component: ShellView,
    children: [
      {
        path: '',
        component: CustomerTable,
      },
      {
        path: 'new',
        component: NewCustomer,
      },
      {
        path: 'edit/:id',
        component: EditCustomer,
      },
    ],
  },
  {
    path: '/employees',
    component: EmployeesView,
    children: [
      {
        path: '',
        component: EmployeeTable,
      },
      {
        path: 'new',
        component: NewEmployee,
      },
      {
        path: 'edit/:id',
        component: EditEmployee,
      },
    ],
  },
  {
    path: '/routes',
    component: RoutesView,
    children: [
      {
        path: '',
        component: RoutesTable,
      },
      {
        path: 'edit',
        component: EditRoute,
      },
      {
        path: 'edit/:id',
        component: EditRoute,
      },
      {
        path: 'employee/:id',
        component: RoutesTable,
      },
    ],
  },
  {
    path: '/reports',
    component: ReportsView,
    children: [
      {
        path: '',
        component: ReportsTable,
      },
      {
        path: 'new',
        component: NewReport,
      },
      {
        path: 'employee/:id',
        component: ReportsTable,
      },
      {
        path: 'view/:id',
        component: ViewSingleReport,
      },
    ],
  },
  {
    path: '/workorders',
    component: WorkordersView,
    children: [
      {
        path: '',
        component: WorkorderTable,
      },
      {
        path: 'new',
        component: NewWorkorder,
      },
      {
        path: 'edit/:id',
        component: EditWorkorder,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
