
import { defineComponent, ref, computed } from 'vue'
import { signOut } from 'firebase/auth'
import { auth } from '@/firebase/config'
import { useRouter } from 'vue-router'
import UserStore from '@/store/UserStore'
import { Slide } from 'vue3-burger-menu'

export default defineComponent({
  name: 'TopBar',
  components: {
    Slide,
  },
  setup() {
    const router = useRouter()
    const isOpen = ref(false)
    const logout = () =>
      signOut(auth)
        .then(() => {
          router.push('/login')
        })
        .catch(error => {
          console.error(error)
        })
    const role = computed(() => {
      return UserStore.getters?.role ? UserStore.getters?.role : 'Employee'
    })

    return { logout, UserStore, isOpen, role }
  },
})
