<template>
  <div className="input-wrapper">
    <label>{{ props.label }}<span v-if="props.required">*</span></label>
    <input
      type="text"
      :placeholder="props.placeholder"
      autocomplete="off"
      :disabled="props.disabled"
      v-model="inputValue"
      @keyup="validateInput"
      @blur="validateInput"
      @input="handleInput"
    />
    <div v-if="errors[props.label]" className="error-wrapper">
      <span className="error">{{ errors[props.label] }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import useFormValidation from '@/modules/useFormValidation'

export default defineComponent({
  name: 'PhoneInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const inputValue = ref('')
    const { validatePhoneField, errors } = useFormValidation()
    const validateInput = () => {
      props.required && validatePhoneField(props.label, inputValue.value)
    }

    const handleInput = () => {
      context.emit('update:modelValue', inputValue.value)
    }

    watch(
      () => props.modelValue,
      val => {
        inputValue.value = val
      },
      { immediate: true },
    )

    watch(
      () => props.validate,
      val => val && validateInput(),
      { immediate: true },
    )

    return { inputValue, errors, validateInput, handleInput, props }
  },
})
</script>
<style scoped>
label {
  font-size: 14px;
  color: var(--text-black);
  font-weight: bold;
  text-transform: uppercase;
}

input {
  padding: 10px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid var(--border-grey);
  margin-bottom: 5px;
  color: var(--text-black);
}

.input-wrapper {
  margin-bottom: 10px;
}
</style>
