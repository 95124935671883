
import { defineComponent } from 'vue'
import TopBar from './TopBar.vue'
import SideBar from './SideBar.vue'

export default defineComponent({
  name: 'ShellWrapper',
  components: {
    TopBar,
    SideBar,
  },
  setup() {
    return {}
  },
})
