
import { defineComponent, ref } from 'vue'
import useFormValidation from '@/modules/useFormValidation'

export default defineComponent({
  name: 'FileInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, context) {
    const fileObj = ref<File | null>()
    const file = ref<HTMLFormElement>()
    const { validateEmailField, errors } = useFormValidation()

    const handleFileUpload = async () => {
      // debugger;
      fileObj.value = file.value?.files[0]
      console.log('selected file', fileObj.value)
      context.emit('update:modelValue', fileObj.value)
      //Upload to server
    }

    return {
      handleFileUpload,
      file,
      props,
      errors,
      validateEmailField,
    }
  },
})
