import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fa7bb26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { className: "input-wrapper" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["placeholder", "disabled", "type"]
const _hoisted_4 = ["placeholder", "disabled"]
const _hoisted_5 = {
  key: 2,
  className: "error-wrapper"
}
const _hoisted_6 = { className: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createTextVNode(_toDisplayString(_ctx.props.label), 1),
      (_ctx.props.required)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
        : _createCommentVNode("", true)
    ]),
    (!_ctx.props.textarea)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 0,
          placeholder: _ctx.props.placeholder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
          disabled: _ctx.props.disabled,
          type: _ctx.props.type,
          onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args))),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args))),
          onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
        }, null, 40, _hoisted_3)), [
          [_vModelDynamic, _ctx.inputValue]
        ])
      : _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 1,
          placeholder: _ctx.props.placeholder,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inputValue) = $event)),
          disabled: _ctx.props.disabled,
          onKeyup: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args))),
          onBlur: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args))),
          onInput: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
        }, null, 40, _hoisted_4)), [
          [_vModelText, _ctx.inputValue]
        ]),
    (_ctx.errors[_ctx.props.label])
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.errors[_ctx.props.label]), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}