
import { defineComponent, ref, onBeforeMount } from 'vue'
import { getAllEmployees, employeeColumns } from '@/firebase/employees'
import exportFromJSON from 'export-from-json'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'EmployeeTable',
  setup() {
    const localEmployees = ref()
    const localEmployeeRows = ref()
    const tableData = ref()

    const downloadTable = () => {
      const data = tableData.value?.processedRows[0]?.children
      const fileName = 'Employees_' + dayjs().format('M-DD-YY-hh:mm:ss')
      const exportType = exportFromJSON.types.csv
      const columnOrder = employeeColumns.map((column: any) => column.field)
      const customSort = (a: any, b: any) => {
        const indexOfA = columnOrder.indexOf(a.fieldName)
        const indexOfB = columnOrder.indexOf(b.fieldName)
        if (indexOfA > -1 && indexOfB > -1) return indexOfA - indexOfB
      }

      const beforeTableEncode = (rows: any) => {
        rows.sort(customSort)
        return rows
      }

      exportFromJSON({ data, fileName, exportType, beforeTableEncode })
    }

    onBeforeMount(async () => {
      const cust = await getAllEmployees()
      localEmployees.value = cust && [...cust]
      localEmployeeRows.value = cust.map(({ firstName, lastName, address, email, phone, id }: any) => ({
        firstName,
        lastName,
        address,
        email,
        phone,
        Actions: id,
      }))
    })

    return {
      localEmployees,
      employeeColumns,
      localEmployeeRows,
      downloadTable,
      tableData,
    }
  },
})
