import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_customer_form = _resolveComponent("customer-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", null, "Edit " + _toDisplayString(_ctx.id), 1),
    _createVNode(_component_customer_form, {
      customer: _ctx.localCustomer,
      onSubmitCustomerForm: _ctx.editCustomer
    }, null, 8, ["customer", "onSubmitCustomerForm"]),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args))),
      className: "delete"
    }, "Delete")
  ], 64))
}