
import { defineComponent, ref, watch } from 'vue'
import useFormValidation from '@/modules/useFormValidation'

export default defineComponent({
  name: 'TextInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validate: {
      type: Boolean,
      default: false,
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  setup(props, context) {
    const inputValue = ref<string>('')
    const { validateTextField, errors } = useFormValidation()
    const validateInput = () => {
      props.required && validateTextField(props.label, inputValue.value)
    }

    const handleInput = () => {
      context.emit('update:modelValue', inputValue.value)
    }

    watch(
      () => props.modelValue,
      val => {
        inputValue.value = val
      },
      { immediate: true },
    )

    watch(
      () => props.validate,
      val => val && validateInput(),
      { immediate: true },
    )

    return {
      props,
      validateInput,
      errors,
      inputValue,
      handleInput,
    }
  },
})
