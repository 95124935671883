import { collection, doc, setDoc, getDocs, getDoc, deleteDoc, query, where, updateDoc } from 'firebase/firestore'
import { db } from '../firebase/config'
export interface WorkorderInt {
  id?: any
  title?: string
  description?: string
  employeeId?: string
  employeeName?: string
  customerId?: string
  customerName?: string
  status?: string
  tags?: []
}

export const addOrder = async ({
  title,
  description,
  employeeId,
  employeeName,
  customerId,
  customerName,
  tags,
}: WorkorderInt) => {
  const orderRef = collection(db, 'orders')
  try {
    const addDoc = await setDoc(doc(orderRef), {
      title,
      description,
      employeeId,
      employeeName,
      customerId,
      customerName,
      tags,
      status: 'In Progress',
      comments: '',
    })
    return addDoc
  } catch (e) {
    console.error('Error: ', e)
  }
  return {}
}

export const getAllOrders = async () => {
  try {
    const orderList = await getDocs(collection(db, 'orders'))
    const results: WorkorderInt[] = []
    orderList.forEach(doc => {
      const data = doc.data()
      results.push({ id: doc.id, ...data })
    })
    return results
  } catch (e) {
    console.error('Error getting orders: ', e)
  }
  return []
}

export const getAllOpenOrders = async () => {
  const ordersRef = collection(db, 'orders')
  const q = query(ordersRef, where('status', '==', 'In Progress'))
  try {
    const orderList = await getDocs(q)

    const results: WorkorderInt[] = []
    orderList.forEach(doc => {
      const data = doc.data()
      results.push({ id: doc.id, ...data })
    })
    return results
  } catch (e) {
    console.error('Error getting order: ', e)
  }
  return []
}

export const getSingleWorkorder = async (id: string) => {
  const docRef = doc(db, 'orders', id)
  try {
    const orderList = await getDoc(docRef)

    const orderListData: WorkorderInt | undefined = orderList.data()
    return orderListData
  } catch (e) {
    console.error('Error getting order: ', e)
  }
  return {}
}

export const deleteWorkorder = async (id: string) => {
  try {
    await deleteDoc(doc(db, 'orders', id))
    return 200
  } catch (e) {
    console.error('Error deleting order: ', e)
  }
  return []
}

export const updateWorkorder = async ({
  title,
  description,
  employeeId,
  employeeName,
  customerId,
  customerName,
  tags,
  status,
  id,
}: WorkorderInt) => {
  try {
    return updateDoc(doc(db, 'orders', id), {
      title,
      description,
      employeeId,
      employeeName,
      customerId,
      customerName,
      tags,
      status,
      comments: '',
    })
  } catch (e) {
    console.error('Error: ', e)
  }
  return {}
}

export const markComplete = async (id: string) => {
  try {
    return updateDoc(doc(db, 'orders', id), {
      status: 'Complete',
    })
  } catch (e) {
    console.error('Error: ', e)
  }
}

export const workorderColumns = [
  {
    label: 'Title',
    field: 'title',
  },
  {
    label: 'Description',
    field: 'description',
  },
  {
    label: 'Employee',
    field: 'employeeName',
    filterOptions: {
      enabled: true,
      placeholder: 'Filter By Employee',
    },
  },
  {
    label: 'Customer',
    field: 'customerName',
  },
  {
    label: 'Status',
    field: 'status',
  },
  {
    label: 'Actions',
    field: 'actions',
  },
]
