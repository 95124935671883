
import { defineComponent } from 'vue'
import ShellWrapper from '@/components/shell/ShellWrapper.vue'

export default defineComponent({
  name: 'ReportsView',
  components: {
    ShellWrapper,
  },
  setup() {
    return {}
  },
})
