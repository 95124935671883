import { collection, doc, setDoc, getDocs, getDoc, deleteDoc, query, where, limit } from 'firebase/firestore'
import { db } from '../firebase/config'

export interface RouteSingleInt {
  customerAddress: string
  customerName: string
}

export interface CustInt {
  id: string
  name: string
  address: string
}
export interface RouteInt {
  id: string
  day: string
  route?: CustInt[]
  employeeId?: string
  employeeName?: string
}

export const addRoute = async ({ day, employeeId, employeeName, route }: RouteInt) => {
  const routesRef = collection(db, 'routes')
  try {
    const addDoc = await setDoc(doc(routesRef, employeeId + '_' + day), { day, employeeId, employeeName, route })
    return addDoc
  } catch (e) {
    console.error('Error: ', e)
  }
  return {}
}

export const getAllRoutes = async () => {
  try {
    const routesRef = await getDocs(collection(db, 'routes'))
    const results: RouteInt[] = []
    routesRef.forEach(doc => {
      const data = doc.data() as RouteInt
      results.push({ ...data, id: doc.id })
    })
    return results
  } catch (e) {
    console.error('Error getting routes: ', e)
  }
  return []
}

export const getAllEmployeeRoutes = async (id: string) => {
  const routesRef = collection(db, 'routes')
  const q = query(routesRef, where('employeeId', '==', id))
  try {
    const routeList = await getDocs(q)
    const results: RouteInt[] = []
    routeList.forEach(doc => {
      const data = doc.data() as RouteInt
      results.push({ ...data, id: doc.id })
    })
    return results
  } catch (e) {
    console.error('Error getting routes: ', e)
  }
  return []
}

export const getAllEmployeeRoutesByDay = async (id: string, day: string) => {
  const routesRef = collection(db, 'routes')
  const q = query(routesRef, where('employeeId', '==', id), where('day', '==', day), limit(1))
  try {
    const route = await getDocs(q)

    let result = {}
    route.forEach(doc => {
      const data = doc.data()
      result = { id: doc.id, ...data }
    })
    return result
  } catch (e) {
    console.error('Error getting routes: ', e)
  }
  return {}
}

export const getSingleRoute = async (id: string) => {
  const docRef = doc(db, 'routes', id)
  try {
    const route = await getDoc(docRef)

    const returnData: RouteInt | undefined = route.data() as RouteInt
    return returnData
  } catch (e) {
    console.error('Error getting route: ', e)
  }
  return {}
}

export const deleteRoute = async (id: string) => {
  try {
    await deleteDoc(doc(db, 'routes', id))
    return 200
  } catch (e) {
    console.error('Error deleting route: ', e)
  }
  return []
}

export const updateRoute = async ({ day, employeeId, employeeName, route, id }: any) => {
  try {
    const updateDoc = await setDoc(doc(db, 'routes', id), {
      day,
      employeeId,
      employeeName,
      route,
    })
    return updateDoc
  } catch (e) {
    console.error('Error: ', e)
  }
  return {}
}

export const routeColumns = [
  {
    label: 'Day',
    field: 'day',
  },
  {
    label: 'Employee Id',
    field: 'employeeId',
  },
  {
    label: 'Employee Name',
    field: 'employeeName',
  },
  {
    label: 'Route',
    field: 'route',
  },
]
