
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'CheckboxInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const inputValue = ref<boolean>(false)
    const handleInput = (event: any) => {
      inputValue.value = event.target.checked
      context.emit('update:modelValue', event.target.checked)
    }

    return {
      props,
      inputValue,
      handleInput,
    }
  },
})
