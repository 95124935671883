import { collection, doc, setDoc, getDocs, getDoc, deleteDoc, updateDoc } from 'firebase/firestore'
import { db } from '../firebase/config'

export interface CustomerInt {
  address?: string
  email?: string
  employeeId?: string
  employeeName?: string
  firstName?: string
  lastName?: string
  phone?: string
  id?: any
  filterSizes?: string
  service?: string
  neighborhood?: string
  sideOfHouse?: string
  gateCodesKeys?: string
  salt?: string
}

export const addCustomer = async ({
  firstName,
  lastName,
  address,
  email,
  employeeId,
  employeeName,
  phone,
  filterSizes,
  service,
  neighborhood,
  sideOfHouse,
  gateCodesKeys,
  salt,
}: CustomerInt) => {
  const accountsRef = collection(db, 'accounts')
  try {
    const addDoc = await setDoc(doc(accountsRef), {
      firstName,
      lastName,
      address,
      email,
      employeeId,
      employeeName,
      phone,
      filterSizes,
      service,
      neighborhood,
      sideOfHouse,
      gateCodesKeys,
      salt,
    })
    return addDoc
  } catch (e) {
    console.error('Error: ', e)
  }
  return {}
}

export const getAllCustomers = async () => {
  try {
    const customerList = await getDocs(collection(db, 'accounts'))
    const results: CustomerInt[] = []
    customerList.forEach(doc => {
      const data = doc.data()
      results.push({ id: doc.id, ...data })
    })
    return results
  } catch (e) {
    console.error('Error getting customers: ', e)
  }
  return []
}

export const getSingleCustomer = async (id: string) => {
  const docRef = doc(db, 'accounts', id)
  try {
    const customerList = await getDoc(docRef)

    const customerListData: CustomerInt | undefined = customerList.data()
    return customerListData
  } catch (e) {
    console.error('Error getting customer: ', e)
  }
  return {}
}

export const deleteCustomer = async (id: string) => {
  try {
    await deleteDoc(doc(db, 'accounts', id))
    return 200
  } catch (e) {
    console.error('Error deleting customer: ', e)
  }
  return []
}

export const updateCustomer = async ({
  firstName,
  lastName,
  address,
  email,
  employeeId,
  employeeName,
  phone,
  id,
  filterSizes,
  service,
  neighborhood,
  sideOfHouse,
  gateCodesKeys,
  salt,
}: CustomerInt) => {
  try {
    return await updateDoc(doc(db, 'accounts', id), {
      firstName,
      lastName,
      address,
      email,
      employeeId,
      employeeName,
      phone,
      filterSizes,
      service,
      neighborhood,
      sideOfHouse,
      gateCodesKeys,
      salt,
    })
  } catch (e) {
    console.error('Error: ', e)
  }
  return {}
}

export const customerColumns = [
  {
    label: 'First Name',
    field: 'firstName',
  },
  {
    label: 'Last Name',
    field: 'lastName',
  },
  {
    label: 'Address',
    field: 'address',
  },
  {
    label: 'Email',
    field: 'email',
  },
  {
    label: 'Employee',
    field: 'employeeName',
  },
  {
    label: 'Phone',
    field: 'phone',
  },
  {
    label: 'Filter Size',
    field: 'filterSizes',
  },
  {
    label: 'Service',
    field: 'service',
  },
  {
    label: 'Neighborhood',
    field: 'neighborhood',
  },
  {
    label: 'Side of House',
    field: 'sideOfHouse',
  },
  {
    label: 'Gate Codes/Keys',
    field: 'gateCodesKeys',
  },
  {
    label: 'Salt',
    field: 'salt',
  },
  {
    label: 'Actions',
    field: 'actions',
  },
]
