<template>
  <div class="wrapper">
    <div className="content">
      <div class="logo">
        <img :src="require('/src/assets/blue-pools-icon.png')" width="100" />
        <h1 class="title">Service Manager</h1>
      </div>
      <h2>Login to Your Account</h2>
      <email-input
        type="text"
        placeholder="Email"
        v-model="email"
        :required="true"
        :validate="validateForm"
        label="Email"
      />
      <text-input
        type="password"
        placeholder="Password"
        v-model="password"
        :required="true"
        :validate="validateForm"
        label="Password"
      />
      <p v-if="errMsg.length > 0" className="login-error">{{ errMsg }}</p>
      <button @click="signIn" class="action submit" :disabled="loading">
        <easy-spinner type="dots" size="15" color="white" v-if="loading" />{{ !loading ? 'Login' : '' }}
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '@/firebase/config'
import { useRouter } from 'vue-router'
import UserStore from '../store/UserStore'
import TextInput from '@/components/inputs/TextInput.vue'
import EmailInput from '@/components/inputs/EmailInput.vue'
import useFormValidation from '@/modules/useFormValidation'

export default defineComponent({
  name: 'LoginView',
  components: {
    TextInput,
    EmailInput,
  },
  setup(props) {
    const email = ref('')
    const password = ref('')
    const errMsg = ref('')
    const loading = ref(false)
    const router = useRouter()
    const { actions: userActions } = UserStore
    const { errors, formHasErrors } = useFormValidation()
    const validateForm = ref(false)

    const signIn = () => {
      validateForm.value = true
      if (formHasErrors.value === false) {
        loading.value = true
        signInWithEmailAndPassword(auth, email.value, password.value)
          .then(data => {
            userActions.loginUser(data.user)
            router.push('/')
          })
          .catch(error => {
            console.error(error)
            switch (error.code) {
              case 'auth/invalid-email':
                errMsg.value = 'Invalid email'
                break
              case 'auth/user-not-found':
                errMsg.value = 'No account with that email was found'
                break
              case 'auth/wrong-password':
                errMsg.value = 'Incorrect password'
                break
              default:
                errMsg.value = 'Email or password was incorrect'
                break
            }
          })
          .finally(() => {
            loading.value = false
          })
      }
    }
    return {
      props,
      signIn,
      email,
      password,
      errMsg,
      UserStore,
      errors,
      formHasErrors,
      validateForm,
      loading,
    }
  },
})
</script>
<style scoped lang="scss">
.wrapper {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .content {
    max-width: 350px;
    width: 100%;
    padding: 100px 0;

    .logo {
      text-align: center;
    }
  }

  h2 {
    text-align: center;
  }

  .login-error {
    background: var(--red);
    color: white;
    margin-top: 10px;
    display: block;
    padding: 10px 10px;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
